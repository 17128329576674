import React from 'react'
import styled from 'styled-components'
import Dialog from '../../../components/modal/Dialog'
import { Button, ItemGroup, Text } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { HeadingLargePadded } from '../../../components/text/Headings'

const selectionsToBeOverridden = ['EQUIPMENT', 'WINTER_TYRES', 'CAR_CONFIG_AGREEMENTS_HEADING']

export default ({ isOpen, handleIsOpen, heading, body, onConfirmChange, onRetainConfiguration }) => {
  return (
    <Dialog open={isOpen} onOpenChange={() => handleIsOpen} width="fit-content">
      <div>
        <TextContainer>
          {heading && (
            <TranslatedText i18nKey={heading}>
              {(value) => <HeadingLargePadded>{value}</HeadingLargePadded>}
            </TranslatedText>
          )}
          <div>
            {body && <TranslatedText i18nKey={body}>{(value) => <Text>{value}:</Text>}</TranslatedText>}
            <ul>
              {selectionsToBeOverridden.map((selection) => (
                <TranslatedText key={selection} i18nKey={selection}>
                  {(value) => (
                    <li>
                      <Text>- {value}</Text>
                    </li>
                  )}
                </TranslatedText>
              ))}
            </ul>
          </div>
        </TextContainer>
        <ItemGroup variant="paginationLeft">
          <Button
            onClick={() => {
            handleIsOpen(false)
            onConfirmChange()
          }}
            variant="warningNormal"
          >
            <TranslatedText i18nKey='CHANGE_CAR'/>
          </Button>
          <Button
            onClick={() => {
            handleIsOpen(false)
            onRetainConfiguration()
          }}
          >
            <TranslatedText i18nKey='KEEP_CAR'/>
          </Button>
          <Button
            onClick={() => handleIsOpen(false)}
          >
            <TranslatedText i18nKey='FORM_RESET'/>
          </Button>
        </ItemGroup>
      </div>
    </Dialog>
  )
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-small);
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
