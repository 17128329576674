import React, { useEffect, useMemo } from 'react'
import { i18nInstance, TranslatedText } from '@staccx/i18n'
import DropDown from '../../../components/DropDownMenu'
import CustomSkeleton from './skeleton/skeleton'
import CarInfoShort from './CarInfoShort'
import styled, { css } from 'styled-components'

const mapDriver = (d) => ({ value: d?.id, label: d?.name })
const mapCar = (c) => ({
  value: c.accountNo,
  label: `${c.registrationNumber} - ${c.description} (${c.accountNo})`,
})

export default ({ roleType, drivers, driver, userCar, cars, car, driverHandler, carHandler }) => {
  const formattedDrivers = useMemo(() => {
    if (!drivers) {
      return null
    }
    const filteredDrivers = drivers?.filter((d) => d?.type !== 'Main Contact')
    return filteredDrivers.map(mapDriver)
  }, [drivers])

  const formattedCars = cars ? cars?.filter((car) => !!car.accountNo && !car.noCarReplacement).map(mapCar) : null

  const replacementCar = cars?.find((c) => c?.accountNo === car?.value) ?? { label: `-- ${i18nInstance.translate('NONE')} --`, value: "default" }

  useEffect(() => {
    if (replacementCar?.driver?.id) {
      if (!driver && replacementCar?.driver?.id !== driver?.value) {
        driverHandler(mapDriver(replacementCar?.driver))
      }
    }
  }, [replacementCar])

 useEffect(() => {
    if (driver && !driver?.value) {
      driverHandler((prevState) => mapDriver(prevState))
    }
  }, [car, driver])

  return (
    <ExtrasContainer>
      {roleType === 2 && (
        <div>
          <TranslatedText i18nKey="CONFIGURATION_REPLACES_VEHICLE">{(value) => <Span>{value}</Span>}</TranslatedText>
          {formattedCars?.length > 0 ? (
            <DropDown
              width={'100%'}
              resetObject={{ label: `-- ${i18nInstance.translate('NONE')} --`, value: "default" }}
              options={formattedCars}
              value={car?.value ? car : { label: `-- ${i18nInstance.translate('NONE')} --`, value: "default" }}
              placeholder={`-- ${i18nInstance.translate('NONE')} --`}
              onChange={(d) => carHandler(d)}
            />
          ) : formattedCars !== null ? (
            <CustomSkeleton
              skeletons={[
                {
                  height: 30,
                  count: 1,
                },
              ]}
              theme={css`
                background-color: var(--color-bg);
                grid-template-columns: 1fr;
              `}
            />
          ): <p className='py-4'>Ingen biler tilgjengelig</p>}
        </div>
      )}
      <div>
        {roleType === 1 && (
          <TranslatedText i18nKey="CONFIGURATION_REPLACES_VEHICLE">{(value) => <Span>{value}</Span>}</TranslatedText>
        )}
        {replacementCar?.value !== "default" && <CarInfoShort car={replacementCar} />}
      </div>

      {roleType === 2 && (
        <div>
          <TranslatedText i18nKey="DRIVER">{(value) => <Span>{value}</Span>}</TranslatedText>
          {formattedDrivers ? (
            <DropDown
              width={'100%'}
              resetObject={{ label: `-- ${i18nInstance.translate('NONE')} --`, value: 'default' }}
              options={formattedDrivers}
              value={driver}
              placeholder={`-- ${i18nInstance.translate('NONE')} --`}
              onChange={(d) => driverHandler(d)}
              overlap={true}
            />
          ) : (
            <CustomSkeleton
              skeletons={[
                {
                  height: 30,
                  count: 1,
                },
              ]}
              theme={css`
                background-color: var(--color-bg);
                display: grid;
                grid-template-columns: 1fr;
              `}
            />
          )}
        </div>
      )}
    </ExtrasContainer>
  )
}

const ExtrasContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`

const Span = styled.span`
  font-size: var(--font-h3);
  font-weight: var(--fontWeight-bold);
  line-height: 1.5em;
`
